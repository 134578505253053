/* ===== Scrollbar CSS ===== */

body {
  --sb-track-color: #1c232f;
  --sb-thumb-color: #ff6600;
  --sb-size: 8px;
}

body::-webkit-scrollbar {
  width: var(--sb-size)
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 4px;
  
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}


/*------------------*/

a {
    color: #ff6600;
    text-decoration: none;
}

.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

  .uppercase {
    text-transform: uppercase;
  }

  .blur {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .blur-bottom-nav {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .border-left {
    border-left: solid 3px rgba(255, 102, 0, 1);
  }
  
  .border-left-flash {
    border-left: solid 3px rgba(255, 102, 0, .5);
    animation: flash-border 1.5s infinite; /* Apply the animation */
  }
  
  @keyframes flash-border {
    0% {
      border-left-color: rgba(255, 102, 0, 0.15);
    }
    50% {
      border-left-color: rgba(255, 102, 0, 1); /* Full opacity at 50% */
    }
    100% {
      border-left-color: rgba(255, 102, 0, 0.15); /* Back to original opacity */
    }
  }

  .border-left-node2 {
    border-left: solid 3px rgba(146, 171, 207, 1);
  }

  .border-left-flash-node2 {
    border-left: solid 3px rgba(146, 171, 207, .5);
    animation: flash-border-node2 1.5s infinite; /* Apply the animation */
  }
  
  @keyframes flash-border-node2 {
    0% {
      border-left-color: rgba(146, 171, 207, 0.15);
    }
    50% {
      border-left-color: rgba(146, 171, 207, 1); /* Full opacity at 50% */
    }
    100% {
      border-left-color: rgba(146, 171, 207, 0.15); /* Back to original opacity */
    }
  }

  .line {
    stroke-dasharray: 260;
    stroke-dashoffset: 260;
    animation: svgAnimation 50s linear infinite;
  }
  
  @keyframes svgAnimation {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 1000;
    }
  }

 /* AUDIO PLAYER */

.CustomAudioPlayerPosition {
  position: fixed;
  bottom: 55px; /* Adjusted to match the new bottom position */
  left: 0;
  right: 0;
  cursor: pointer;
}

.rhap_container {
  background-color: #1d1d1d !important; /* Ensure the player has a black background */
  color: #fff; /* Set text color to white for contrast */
  box-shadow: none !important;
  padding: 10px 7px !important;
}

.rhap_progress-container {
  bottom: 115px !important;
}

@keyframes blink {
  0% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 0 }
  }

.rhap_progress-filled-live {
  animation: blink 2s linear infinite;
  background-color: rgb(255, 102, 0) !important;
}

.rhap_progress-filled-toggle {
  animation: blink 2s linear infinite;
  background-color: rgb(255, 102, 0) !important;
}

.rhap_controls-section {
  flex: none !important;
}

.rhap_horizontal .rhap_controls-section {
  margin-left: 0px !important;
}

.rhap_volume-container {
  display: none !important;
}

.rhap_time {
  font-size: 12px !important;
    color: rgb(153, 153, 153) !important;
    display: none !important;
    
}


/* =================================== */
